import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { ArticleCard } from "../components/Article/ArticleCard"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { SideMenuNews } from "../components/Navigation/SidemenuNews"

const NewsCategory = ({ data }) => {
  const { contentfulCategory } = data

  return (
    <Layout>
      <SEO
        title={contentfulCategory.title}
        description={
          contentfulCategory.title &&
          `Latest Property News from the ${contentfulCategory.title} archives`
        }
      />
      <Container>
        <Row>
          <Col>
            <h1>{contentfulCategory.title}</h1>
          </Col>
        </Row>
      </Container>
      <CurveCarveTop variant="light" />
      <div className="bg-light  py-5">
        <Container>
          <Row>
            <Col lg={8}>
              <Row>
                {contentfulCategory.article ? (
                  contentfulCategory.article.map(article => {
                    return (
                      <Col sm={6} key={article.id}>
                        <ArticleCard article={article} />
                      </Col>
                    )
                  })
                ) : (
                  <div>No Articles</div>
                )}
              </Row>
            </Col>
            <Col>
              <SideMenuNews />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query NewsCategoryQuery($id: String) {
    contentfulCategory(id: { eq: $id }) {
      id
      title
      article {
        ...ArticleListFragment
      }
    }
  }
`

export default NewsCategory
